<template >
  <map-card title="Llamada SOS" v-if="isVisible()" :actions="actions">
    <template v-slot:body>
      <v-card flat class="d-flex flex-column fill-height" style="background-color: transparent;" dark>
        <v-card-text class="flex-grow-1 overflow-y-auto">
          <template v-for="(msg, index) in sosMessages">
            <div :key="index" :class="{ 'd-flex flex-row-reverse': msg.me }" >
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-hover >
                    <v-chip :color="msg.me ? 'primary' : ''" dark style="height:auto;white-space: normal;"
                      class="pa-4 mb-2" v-on="on">
                      {{ msg.text }}
                      <sub class="ml-2" style="font-size: 0.5rem;">{{ msg.time }}</sub>
                    </v-chip>
                  </v-hover>
                </template>
              </v-menu>
            </div>
          </template>
        </v-card-text>
        <v-card-text class="flex-shrink-1">
          <v-text-field v-model="newMessage" type="text" no-details outlined append-outer-icon="mdi-send" hide-details
            @keyup.enter="sendMessage(newMessage)">
          </v-text-field>
        </v-card-text>
      </v-card>
    </template>
  </map-card>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions, mapState } from 'vuex';
import motivosCad from '@/data/motivosReporteCad.json';
import Dialog from '../../dialog'
import Form from '../../form'

export default {
  components: {
    'map-card': () => import('@/components/MapCard.vue')
  },
  computed: {
    ...mapGetters('sosMessages', ['getSosMessages']),
    ...mapState('map', ['sosActiveCall']),
    sosMessages() {
      const ret = []
      if (this.getSosMessages?.length > 0) {
        this.getSosMessages.forEach(element => {
          if (element?.emerCallId && element?.emerCallId === this.sosActiveCall?._id) {
            ret.push({
              text: element.message,
              time: moment(element.createdAt).format('HH:mm:ss'),
              me: element.sender === 'operator'
            })
          }
        })
      }
      return ret
    }
  },
  methods: {
    ...mapActions('map', ['setActiveCall']),
    sendMessage(msg) {
      window.VMA.mapComponent.sendSosMessage(msg)
      this.newMessage = ''
    },
    isVisible() {
      return this.sosActiveCall?._id
    }
  },
  data() {
    return {
      newMessage: '',
      messages: [],
      actions: [
        {
          icon: 'mdi-camera-flip',
          help: 'CAMBIAR CAMARA',
          action: () => {
            if (this.sosActiveCall && window.VMA.mapComponent.appClientWs) {
              console.log('EMIT')
              window.VMA.mapComponent.appClientWs.emit('set_camera', 'changeCamera');
            }
          }
        },
        {
          icon: 'mdi-file-document-edit-outline',
          help: 'GENERAR REPORTE',
          action: () => {
            console.log('GENERAR REPORTE');
            if (this.sosActiveCall && window.VMA.mapComponent.appClientWs) {
              const form = new Form({
                schema: {
                  reason: {
                    label: 'Motivo',
                    type: String,
                    options: motivosCad.map((m) => ({ id: m, name: m })),
                  },
                  description: {
                    label: 'Descripción',
                    type: String,
                    textarea: true,
                  },
                }
              })
              const dialog = new Dialog({
                title: 'Generar reporte',
                subtitle: '',
                actions: [{
                  color: 'success',
                  help: 'Guardar',
                  icon: 'mdi-content-save',
                  action: () => {
                    if (form.hasErrors()) {
                      return
                    }
                    const reportData = {
                      callId: this.sosActiveCall._id,
                      ...form.getItem()
                    }
                    console.log(reportData)
                    window.VMA.mapComponent.appClientWs.emit('report_incident', form.getItem());
                  }
                }]
              })
              dialog.open()
              dialog.append(form)
            }
          }
        },
        {
          icon: 'mdi-phone-hangup',
          help: 'COLGAR LLAMADA',
          action: () => {
            console.log('Colgar llamada');
            if (this.sosActiveCall && window.VMA.mapComponent.appClientWs) {
              window.VMA.mapComponent.appClientWs.emit('end_call', this.sosActiveCall?._id);
            }
          }
        },
      ]
    }
  },
}
</script>
